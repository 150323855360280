
import { Component, Vue } from 'vue-property-decorator'
import Echarts from 'echarts'

@Component
export default class PostHocReport extends Vue {
  IssueQuestion = {}
  questionTable = []
  _chart: any = null
  _chart1: any = null
  loading = false

  created () {
    this.get6MonthIssueStatistic()
    this.selectUnRectifyIssuePage()
  }

  beforeDestroy () {
    this._chart1 && this._chart1.dispose()
    this._chart && this._chart.dispose()
    window.removeEventListener('resize', this.eChartResize)
  }

  eChartResize () {
    this._chart1 && this._chart1.resize()
    this._chart && this._chart.resize()
  }

  getIssueFileUrl (files: any) {
    return files.map((item: any) => {
      return item.filePrefix + item.fileUrl
    })
  }

  // 近6月月度统计+问题状态统计
  get6MonthIssueStatistic () {
    this.$axios.get(this.$apis.inspectionReport.select6MonthIssueStatistic).then(res => {
      this.IssueQuestion = res
      this.$nextTick(() => {
        this.initChart(res)
        this.initChart1(res.monthAmounts)
      })
    }).finally(() => {
      this.loading = false
    })
  }

  // 遗留问题
  selectUnRectifyIssuePage () {
    this.$axios.get(this.$apis.inspectionReport.selectUnRectifyIssuePage).then(res => {
      this.questionTable = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  initChart (data: any) {
    this._chart = Echarts.init(this.$refs.echart as any)
    const option = {
      tooltip: {
        trigger: 'axis',
        formatter: function (res: any) {
          console.log('resresres', res)
          return `${res[0].name}<br>
                  ${res[0].marker}${res[0].seriesName} ${res[0].value}<br>
                  ${res[1].marker}${res[1].seriesName} ${res[1].value}<br>
                  ${res[2].marker}${res[2].seriesName} ${res[2].value ? res[2].value + '%' : ''}`
        }
      },
      color: ['#FFC117', '#5B8FF9', '#36CBCB'],
      grid: {
        top: 40,
        bottom: 30,
        right: 50,
        left: 50
      },
      legend: {
        show: true,
        itemWidth: 20,
        itemHeight: 1,
        left: 40,
        top: 10
      },
      xAxis: [
        {
          type: 'category',
          data: data.dateX,
          axisTick: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, .45)'
            }
          },
          axisLabel: {
            color: 'rgba(0, 0, 0, .45)',
            fontSize: 12
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          min: 0,
          axisLabel: {
            formatter: '{value}',
            color: 'rgba(0, 0, 0, .45)',
            fontSize: 12
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          }
        },
        {
          type: 'value',
          axisLabel: {
            formatter: '{value}%'
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          }
        }
      ],
      series: [
        {
          name: '整改数',
          type: 'bar',
          barWidth: 16,
          data: data.rectifyCountsY
        },
        {
          name: '问题数',
          type: 'bar',
          barWidth: 16,
          data: data.issueCountsY
        },
        {
          name: '整改率',
          type: 'line',
          smooth: true,
          yAxisIndex: 1,
          data: data.rectifyRatesY
        }
      ]
    }
    this._chart.clear()
    this._chart.setOption(option)
  }

  initChart1 (data: any) {
    const state = ['待反馈', '待审核', '已完成', '待返工', '待确认']
    const items: any = []
    state.forEach((item: any, index: number) => {
      items.push({ name: item, value: data['issueAmount' + index] })
    })
    const count = items.reduce((prev: any, next: any) => {
      return prev + next.value
    }, 0)
    let num = 0
    let lastPer = ''
    this._chart1 = Echarts.init(this.$refs.echart1 as any)
    const option = {
      tooltip: {
        trigger: 'item'
      },
      title: {
        text: `{a|问题总数\n}{b|${count}}`,
        left: '31%',
        top: '44%',
        textStyle: {
          lineHeight: 36,
          rich: {
            a: {
              fontSize: 14,
              color: '#999999',
              align: 'center'
            },
            b: {
              fontSize: 24,
              color: 'rgba(0, 0, 0, 0.85)',
              align: 'center',
              padding: [20, 0, 0, 0]
            }
          }
        }
      },
      color: ['#47A2FF', '#1AD1B0', '#FBD44C', '#9760E5'],
      legend: {
        left: '55%',
        top: '40%',
        orient: 'vertical',
        icon: 'circle',
        itemGap: 16,
        itemWidth: 8,
        itemHeight: 8,
        formatter: (name: any) => {
          if (items && items.length) {
            for (let i = 0; i < items.length; i++) {
              if (name === items[i].name) {
                let itemNum
                if (count === 0) {
                  itemNum = 0
                } else {
                  itemNum = items[i].value ? (items[i].value / count * 100).toFixed(2) : '0.00'
                  if (i < items.length - 1) {
                    num += Number(itemNum)
                  } else {
                    if (+itemNum !== 0) {
                      if (!lastPer) {
                        lastPer = (100 - num).toFixed(2)
                      }
                      itemNum = lastPer
                    }
                  }
                }
                return (
                  '{a|' + name + '}{b| |  ' + itemNum + '%}    ' + '{c|' + (items[i].value) + '}'
                )
              }
            }
          }
        },
        textStyle: {
          rich: {
            a: {
              fontSize: 14,
              color: '#666666',
              width: 100
            },
            b: {
              fontSize: 14,
              color: '#999999',
              width: 60
            },
            c: {
              fontSize: 14,
              color: '#333333',
              width: 30,
              align: 'right'
            }
          }
        }
      },
      series: [
        {
          name: '问题状态统计',
          type: 'pie',
          radius: ['35%', '45%'],
          left: '-30%',
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            show: false,
            position: 'center'
          },
          labelLine: {
            show: false
          },
          data: items
        }
      ]
    }
    this._chart1.clear()
    this._chart1.setOption(option)
  }
}
